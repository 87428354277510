import { LIST_OF_USER,RESET_PASSWORD,DELETE_USER,SAVE_USER,RESET_MESSAGE_TEST_QUERY_CUSTOM,LOADING,LOADED,RESET_PASSWORD_MESSAGE} from "../constants/actionTypes";
import * as api from '../api/index.js';
import logMessage from "../logserver.js";
import {toast } from 'react-toastify';
import { decryptData } from "../Components/utils/EncriptionStore";

const user = (() => {
  const encryptedData = localStorage.getItem("profile");
  return encryptedData ? decryptData(encryptedData) : null;
})();

export const  listOfuser= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING,});
    try {
        const data = await api.listOfuser(formData);
        
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: LIST_OF_USER, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const resetPassword = (formData, router) => async (dispatch) => {
    try {
        const {data} = await api.resetPassword(formData);
        if (data?.status === 'success') {
            dispatch({ type: RESET_PASSWORD, data });
            // alert("Password Reset Successfully!")
            router("/UserManagementList",{state:{message:"Password Reset Successfully!"}})     
        }else {
            dispatch({ type: RESET_PASSWORD, data });
            // alert(data.message);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const resetmessagePassword = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_PASSWORD_MESSAGE});
    }catch (error) {
      }
}

export const deleteUser = (formData, router) => async (dispatch) => {
    try {
        const {data} = await api.deleteUser(formData);
        if (data?.status === 200) {
            dispatch({ type: DELETE_USER, formData });
        }else {
            toast.success(data?.message || "user is deleted", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,theme: "light",});
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const saveUser = (formData, router) => async (dispatch) => {
    try {
        const {data} = await api.saveUser(formData);
        dispatch({ type: SAVE_USER, data });
        if (data?.message === 'User Added Successfully!') {
            // setTimeout("alert(User Added Successfully)", 2000);
            router("/UserManagementList",{state:{message:"User Added Successfully!"}})     
        }else {
            // alert("User is not valid");
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const  resetmessageshown= () => async (dispatch) => {
    
    dispatch({ type: RESET_MESSAGE_TEST_QUERY_CUSTOM});
    // if (data?.validate) {
    //    dispatch(auth(formData, router));
    // }else {
    //     alert("User is not valid");
    // }
}



