import { GET_REPORT_ON_DASHBOARD,REMOVE_REPORT,UPDATE_REPORT,GENERATE_REPORT_BY_ID,LOADED,LOADING,GET_REPORT_DETAIL_BY_ID,GET_REPORTS_ACCESS_GROUP_ID,GET_REPORT_ACCESS_DETAIL,
    GET_CHART_REPORT_DETAIL_BY_ID,GET_BOX_REPORT_DETAIL_BY_ID,GET_LIST_OF_COLUMN_FIRST,GET_LIST_OF_COLUMN_SECOND,REMOVE_LIST_OF_COLUMN_FIRST,REMOVE_LIST_OF_COLUMN_SECOND,
    SAVE_MAP_DATA_FOR_DRILLDOWN,GET_DRILL_DOWN_DATA,INITIAL_GET_DRILL_DOWN_DATA,CHECK_DRILL_DOWN,DEFAULT_CHECK_DRILL_DOWN,CHAT_BOT_TEXT_TO_QUERY,CHAT_BOT_TEXT_TO_QUERY_RESET,
    UPDATE_MAP_DATA_FOR_DRILLDOWN,DEFAULT_GET_DRILLDOWN_FOR_UPDATE,GET_DRILLDOWN_FOR_UPDATE,ADD_UPDATE_GET_REPORT_ON_DASHBOARD,UPDATE_GET_REPORT_ON_DASHBOARD , GET_CHART_REPORT_DETAIL_BY_ID_BEFORE} from "../constants/actionTypes";
import * as api from '../api/index.js';
import logMessage from "../logserver.js";
import {toast } from 'react-toastify';
import  ShowAlert  from './ShowAlert.js';
import { decryptData } from "../Components/utils/EncriptionStore";

const user = (() => {
  const encryptedData = localStorage.getItem("profile");
  return encryptedData ? decryptData(encryptedData) : null;
})();

export const  getreporttitlefromondashbaord= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
        const data = await api.getreporttitlefromondashbaord(formData);
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: GET_REPORT_ON_DASHBOARD, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error){
        console.log(error.message);
    }
}

export const  getreportdetailwithaccessforassignreport= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
        const data = await api.getreportdetailwithaccessforassignreport(formData);
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: GET_REPORT_ACCESS_DETAIL, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error){
        console.log(error.message);
    }
}

export const  checkdrilldown= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING});
    
    try {
        const data = await api.checkdrilldown(formData);
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: CHECK_DRILL_DOWN, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error){
        console.log(error.message);
    }
}

export const  defaultcheckdrilldown= () => async (dispatch) => {
    try{
        dispatch({ type: DEFAULT_CHECK_DRILL_DOWN,});
        dispatch({type: LOADED });
    }catch (error){
        console.log(error.message);
    }
}


export const  getdataforDrilldown= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
        const {data} = await api.getdataforDrilldown(formData);
        
        dispatch({ type: GET_DRILL_DOWN_DATA, data });
        dispatch({type: LOADED });
        // if (data?.validate) {
        //    dispatch(auth(formData, router));
        // }else {
        //     alert("User is not valid");
        // }
    }catch (error){
        console.log(error.message);
    }
}

export const  drilldowninsitialvalue= () => async (dispatch) => {
    
        dispatch({ type: INITIAL_GET_DRILL_DOWN_DATA, });
       
}



export const  getReportDetailByID= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
        const data = await api.getReportDetailByID(formData);
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: GET_REPORT_DETAIL_BY_ID, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

// export const  removereport = (formData, router) => async (dispatch) => {
//     try {
//         const data = await api.removereport(formData);
//         if (data?.status === 200) {
//             dispatch({ type: REMOVE_REPORT, formData }); 
//             logMessage(user?.user_email_id,data?.status,data?.statusText);
//         }else if(data?.status === 204) {
//             logMessage(user?.user_email_id,data?.status,data?.statusText);
//         }
//     }catch (error) {
//         console.log(error.message);
//     }
// }
export const removereportaftercheck = (formData,router)  => async (dispatch) =>{
    
    try {
        const data = await api.removereportaftercheck(formData);
        
        if (data?.status === 200) {
            dispatch({ type: REMOVE_REPORT, formData });
            logMessage(user?.user_email_id,data?.status,data?.data);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.data);
        }
    }catch (error) {
        console.log(error.message);
    }
}
 
export const  removereport = (formData, router) => async (dispatch) => {
    try {
        const data = await api.removereport(formData);
        
        if (data?.status === 200 && data?.data?.Reports.length > 0) {
            const userConfirmed = await ShowAlert({
                title: "Confirmation",
                message:`This drilldown report is mapped with the master report ${data?.data?.Reports[0]}. Still do you want to continue delete?`,
                options: ["OK", "Cancel"]
            });
            if (userConfirmed === "OK") {
                dispatch(removereportaftercheck(formData, router));
            }
            logMessage(user?.user_email_id,data?.status,data?.data);
        }else if(data?.status === 200 && data?.data?.Reports.length === 0) {
            dispatch(removereportaftercheck(formData, router));
            logMessage(user?.user_email_id,data?.status,data?.data);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.data);
        }
    }catch (error) {
        console.log(error.message);
    }
}

export const  updateReportdetail = (formData, router) => async (dispatch) => {
    try {
        const data = await api.updateReportdetail(formData);
        if (data?.status === 200) {
            localStorage.removeItem("customeDetailOfReport");
            localStorage.removeItem("uploadLogo");
            dispatch({ type: UPDATE_REPORT, data });
            router('/ListOfReports')
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            toast.success(data?.message || "Report Details updated", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,theme: "light",});
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
    }
}

export const  saveMapDataForDrillDown = (formData, router) => async (dispatch) => {
    try {
        
        const data = await api.saveMapDataForDrillDown(formData);
        if (data?.status === 200) {
            dispatch({ type: SAVE_MAP_DATA_FOR_DRILLDOWN, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            toast.success(data?.data || "Table Mapping done", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,theme: "light",});
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const  updateMapDataForDrillDown = (formData, router) => async (dispatch) => {
    try {
        
        const data = await api.updateMapDataForDrillDown(formData);
        if (data?.status === 200) {
            dispatch({ type: UPDATE_MAP_DATA_FOR_DRILLDOWN, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            toast.success(data?.data || "Table Mapping updated", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,theme: "light",});
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const  generateReportId = (formData, router) => async (dispatch) => {
    try {
        const data = await api.generateReportId(formData);
        if (data?.status === 200) {
            dispatch({ type: GENERATE_REPORT_BY_ID, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const getlistofcolumnformappingfirst = (formData, router) => async (dispatch) => {
    try {
        const data = await api.getlistofcolumnformappingfirst(formData);
        if (data?.status === 200) {
            dispatch({ type: GET_LIST_OF_COLUMN_FIRST, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }

}

export const removelistofcolumnformappingfirst = () => async (dispatch) => {
        dispatch({ type: REMOVE_LIST_OF_COLUMN_FIRST});

}
export const removelistofcolumnformappingsecond = () => async (dispatch) => {
    
        dispatch({ type: REMOVE_LIST_OF_COLUMN_SECOND});

}


export const getlistofcolumnformappingsecond = (formData, router) => async (dispatch) => {
    try {
        const data = await api.getlistofcolumnformappingsecond(formData);
        if (data?.status === 200) {
            dispatch({ type: GET_LIST_OF_COLUMN_SECOND, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }


}

export const  generateChartTypeReport= (formData, router) => async (dispatch) => {
    // dispatch({type: LOADING});
    try {
        const data = await api.generateChartTypeReport(formData);
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: GET_CHART_REPORT_DETAIL_BY_ID, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const  generateBoxTypeReport= (formData, router) => async (dispatch) => {
    try {
        const data = await api.generateBoxTypeReport(formData);

        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: GET_BOX_REPORT_DETAIL_BY_ID, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const  getreportaccessonbasisofgroupid = (formData, router) => async (dispatch) => {
    try {
        const data = await api.getreportaccessonbasisofgroupid(formData);
        if (data?.status === 200) {
            dispatch({ type: GET_REPORTS_ACCESS_GROUP_ID, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const  texttoquerychartbot = (formData, router) => async (dispatch) => {
    try {
        const data = await api.texttoquerychartbot(formData);
        if (data?.status === 200) {
            dispatch({ type: CHAT_BOT_TEXT_TO_QUERY, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        logMessage(user?.user_email_id,error.data?.status,error.data?.statusText);
      }
}



export const texttoquerychartbotreset = () => async (dispatch) => {
    dispatch({ type: CHAT_BOT_TEXT_TO_QUERY_RESET});
}

export const  getDrilldowndetailforupdated = (formData, router) => async (dispatch) => {

    try {
        const data = await api.getDrilldowndetailforupdated(formData);
        if (data?.status === 200) {
            dispatch({ type: GET_DRILLDOWN_FOR_UPDATE, data });
            logMessage(user?.user_email_id,data?.status,data?.data);
        }else if(data?.status === 204) {
            toast.error(data?.data || "Not able to get the drilldown data may we issue with maping", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,theme: "light",});
            logMessage(user?.user_email_id,data?.status,data?.data);
        }
    }catch (error) {
        console.log(error.message);
    }

}



export const  defaultgetDrilldowndetailforupdated= () => async (dispatch) => {

    dispatch({ type: DEFAULT_GET_DRILLDOWN_FOR_UPDATE, });

}

export const updategetreporttitlefromondashbaord = (formData) => async (dispatch) => {
    try{
        dispatch({ type: UPDATE_GET_REPORT_ON_DASHBOARD,formData});
        dispatch({type: LOADED });
    }catch (error){
        console.log(error.message);
    }
}
 
export const addgetreporttitlefromondashbaord = (formData) => async (dispatch) => {
    try{
        dispatch({ type: ADD_UPDATE_GET_REPORT_ON_DASHBOARD,formData});
        dispatch({type: LOADED });
    }catch (error){
        console.log(error.message);
    }
}

export const  generateChartTypeReportbefore= () => async (dispatch) => {
    try {
        dispatch({ type: GET_CHART_REPORT_DETAIL_BY_ID_BEFORE, });  
    }catch (error) {
        console.log(error.message);
    }
}
 