import React, { useEffect, useMemo, useState } from 'react';
import Header from '../header';
import { useNavigate } from 'react-router-dom';
import { assigngrouptouser } from '../../actions/auth';
import { listofgroup } from '../../actions/newgroup';
import { listOfuser } from '../../actions/usermanagement';
import { useDispatch, useSelector } from "react-redux";
import './../globalCSS/usermanagement/updategroup.css';
import Pagination from '../Pagination/Pagination';
import styles from './../globalCSS/SearchTable/SearchTable.module.css';
import { Button } from '../globalCSS/Button/Button';
import ShowAlert from '../../actions/ShowAlert';
import { decryptData } from '../utils/EncriptionStore';

function UpdateGroup() {
  const user = (() => {
    const encryptedData = localStorage.getItem("profile");
    return encryptedData ? decryptData(encryptedData) : null;
  })();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const history = useNavigate();
  const apiData = useSelector((state) => state);

  useEffect(() => {
    dispatch(listofgroup({ email: user.user_email_id, database_type: "mysql" }));
    dispatch(listOfuser({ email: user.user_email_id, database_type: "mysql" }));
  }, []);

  const listofuser = apiData?.usermanagement.allUserDetail;
  const listofallgroup = apiData?.newgroup.list_of_group;

 

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const handelclickgotoDashboard = () => {
    history('/Dashboard');
  };

  let PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);


  useEffect(() => {
    if (listofuser) {
      const initialCheckboxes = listofuser?.map(user => ({
        user_email: user?.user_email_id,
        group_id: user?.asscoiated_groups
      }));
      setCheckboxes(initialCheckboxes);
    }
  }, [listofuser]);


  const filteredData = useMemo(() => {
    if (!search) return listofuser;
    return listofuser?.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, listofuser]);

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredData, PageSize]);

  const handelsave = async (e) => {

    try {
      const userConfirmed = await ShowAlert({
        title: "Confirmation",
        message: "Do you want to change the role?",
        options: ["OK", "Cancel"]
      });
      if (userConfirmed === "OK") {
        const user_details = checkboxes.map(item => ({
          user_email: item.user_email,
          group_id: item.group_id,
        }));

        dispatch(assigngrouptouser({ "customer_id": user.customer_id, "user_details": user_details }, history));
      } else {
        console.log("User canceled the operation.");
      }
    } catch (error) {
      console.error("Error saving user details:", error);
    }
  };

  const handleCheckboxChange = (userEmail, groupId) => {
    setCheckboxes((prev) => {
      const updatedCheckboxes = [...prev];
      const userIndex = updatedCheckboxes.findIndex(item => item.user_email === userEmail);

      if (userIndex !== -1) {
        const userGroups = updatedCheckboxes[userIndex].group_id;
        if (userGroups.includes(groupId)) {
          updatedCheckboxes[userIndex].group_id = userGroups.filter(id => id !== groupId);
        } else {
          updatedCheckboxes[userIndex].group_id.push(groupId);
        }
      } else {
        updatedCheckboxes.push({ user_email: userEmail, group_id: [groupId] });
      }

      return updatedCheckboxes;
    });
  };

  const handleclickgotoUserManagement = () => {
    history("/UserManagementList");
  };

  return (
    <div>
      <div className="Header">
        <Header />
      </div>
      <div className="Report_Assign_page">
        <span
          className="fas fa-house-user"
          aria-hidden="true"
          onClick={handelclickgotoDashboard}
        ></span>
        <span>/</span>
        <span onClick={handleclickgotoUserManagement}>User Management</span>
        <span>/</span>
        <span>Assign User to the Group</span>
      </div>
      <div className='Save_Reset_button_cnt'>
        <Button onClick={handelsave}>Save</Button>
      </div>
      <div className='UpdateGroup_table_sub_container'>
        <table className='table table-striped table-bordered table-hover' style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>User Name</th>
              {listofallgroup
                ?.filter((colname) => colname.groupname !== "SuperAdmin")
                .map((colname, index) => (
                  <th style={{ textAlign: "center" }} key={index}>
                    {colname.groupname}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {/* Empty row after the header */}
            <tr>
              <td>
                <div className='UpdateGroup_search'>
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className={styles.inputSearch}
                    placeholder="Search"
                    value={search}
                    maxLength={120}
                    onChange={e => setSearch(e.target.value)}
                  />
                </div>
              </td>
              {listofallgroup && listofallgroup?.filter((colname) => colname.groupname !== "SuperAdmin").map((colname, colIndex) => (
                <td key={`empty_${colIndex}`}></td>
              ))}
            </tr>

            {/* Data rows */}
            {paginatedData && paginatedData?.filter((colname) => colname.user_email_id !== "superadmin@erasmith.com").map((user, userIndex) => (
              <tr key={userIndex}>
                <td>{user.user_email_id}</td>
                {listofallgroup && listofallgroup?.filter((colname) => colname.groupname !== "SuperAdmin").map((colname, colIndex) => (
                  <td key={colIndex} style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={
                        checkboxes.some(item => item.user_email === user.user_email_id && item.group_id.includes(colname.group_id))
                      }
                      onChange={() => handleCheckboxChange(user.user_email_id, colname.group_id)}
                      className={colname.group_id ? 'checked' : 'unchecked'}
                      id={`customCheck_${userIndex}_${colIndex + 1}`}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filteredData ? filteredData.length : 1}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}

export default UpdateGroup;
