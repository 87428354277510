import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import HighCharts from "../HighCharts/HighCharts";
import ReportDashBoardNew from "../DefaultPage/ReportDashBoardNew";
import { canvashframedataformodification } from "../../actions/canvascreation";
import { listofgroup } from "../../actions/newgroup";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import "./../globalCSS/NewDashboard/NewTabs.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { decryptData } from "../utils/EncriptionStore";

const ResponsiveGridLayout = WidthProvider(Responsive);

const NewTabs = ({ sideBarWidth }) => {
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [rightSideWidth, setRightSideWidth] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [visibleTabs, setVisibleTabs] = useState(0);

  const tabContainerRef = useRef(null);

  const tabRefs = useRef([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setRightSideWidth(window.innerWidth - sideBarWidth);
      setPercentage((rightSideWidth / windowWidth) * 100);
      updateVisibleTabs();
    };

    const updateVisibleTabs = () => {
      if (tabContainerRef.current) {
        const containerWidth = tabContainerRef.current.offsetWidth;

        let totalTabWidth = 0;

        let count = 0;

        tabRefs.current.forEach((tab) => {
          totalTabWidth += tab.offsetWidth;

          if (totalTabWidth <= containerWidth) {
            count += 1;
          }
        });

        setVisibleTabs(count);
      }
    };

    // Initial calculation
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [rightSideWidth, sideBarWidth, windowWidth]);


  const [activeTab, setActiveTab] = useState(1);
  const history = useNavigate();
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state);

  const user = (() => {
    const encryptedData = localStorage.getItem("profile");
    return encryptedData ? decryptData(encryptedData) : null;
  })();
 
  const loginuser = user?.loginuser;
  const [freamData, setfreamData] = useState([]);
  useEffect(() => {
    dispatch(
      canvashframedataformodification({
        customer_id: user.customer_id,
        group_id: user.group_id,
      })
    );
    dispatch(
      listofgroup({ email: user.user_email_id, database_type: "mysql" })
    );
  }, []);
  const frameChartdata = apiData?.canvascreation.canvasframedetail;


  const listofallgroup = apiData?.newgroup.list_of_group;

  useEffect(() => {
    const frames = frameChartdata?.frames?.[0]?.frame || [];
    if (frames.length > 0) {
      
      setfreamData(frames);
      setActiveTab(0);
    } else {
      console.log('No value found in localStorage for the key "finalfream"');
    }
  }, [frameChartdata]);

  

  // const onTabClick = (e, index) => {
  //   setActiveTab(index);
  //   console.log(tabs[index].title, "activeTab");
  //   setTabContent(tabs[index].content);
  // };

  const tabs =
    Array.isArray(frameChartdata?.frames) && frameChartdata?.frames?.length > 0
      ? frameChartdata?.frames
      : [];
  const onTabClick = (e, index) => {
    setActiveTab(index);


    // Set freamData based on the active tab's frame
    setfreamData(tabs[index].frame);
  };

  return (
    <div
      style={{
        marginLeft: "-9px",
        // width: `${percentage - 8}%`,
        width: "93.5vw",
      }}
    >
      <div style={{ minWidth: "100%" }}>
        {" "}
        <Tabs
          activeTab={activeTab}
          onTabClick={onTabClick}
          hideNavBtnsOnMobile={false}
          leftBtnIcon={
            <FiChevronLeft
              size={"1.5em"}
              style={{
                color: "black",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            />
          }
          rightBtnIcon={
            <FiChevronRight
              size={"1.5em"}
              style={{
                color: "black",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            />
          }
        >
          {tabs.map((tab) => (
            <Tab key={tab}>{tab.dashboard_name}</Tab>
          ))}
        </Tabs>
      </div>

      <div>
        {frameChartdata?.frames?.length === 0 ||
        frameChartdata?.length === 0 ? (
          <ReportDashBoardNew />
        ) : (
          <div className="layoutSuperContainer">
            {" "}
            <ResponsiveGridLayout
              className="layout"
              layouts={{ lg: freamData }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 20, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={30}
              isResizable={false}
              isDraggable={false}
            >
              {freamData &&
                freamData.map((item) => {
                  
               

                  return (
                    <div
                      className="box-layout-and-chart"
                      key={item.i}
                      style={{
                        border: "1px solid gray",
                        // boxShadow:
                        //   "0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 4px 6px 0 rgba(0, 0, 0, 0.19)",
                        background: "white",
                        overflow: "hidden",
                        borderRadius: "5px",
                        width: `${item.w * 10}%`,
                        height: `${item.h * 30}px`,
                      }}
                    >
                      <HighCharts
                        width={`${item.w * 100}%`}
                        height={`${item.h * 38}px`}
                        charttype={item.chartType}
                        chartColours={item.chart_colours}
                      />
                    </div>
                  );
                })}
            </ResponsiveGridLayout>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewTabs;
