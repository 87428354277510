import React, { useState, useEffect,useRef } from 'react';
import Logo from './Images/small2logoOnly.png';
import { useNavigate, useLocation } from 'react-router-dom';
import './globalCSS/header.css';
import { decryptData } from './utils/EncriptionStore';

function Header() {
  const history = useNavigate();
  const location = useLocation();

  const user = (() => {
    const encryptedData = localStorage.getItem("profile");
    return encryptedData ? decryptData(encryptedData) : null;
  })();

  const [dropdown, setDropDown] = useState("none");
  const [activeTab, setActiveTab] = useState(location.pathname);
  const userfeature = user.features.filter((item) => user.group_id === item.group_id).map(item => item.featurename);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const handleTabClick = (path) => {
    setActiveTab(path);
    history(path);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropDown("none");
    }
  };
 
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const logout = () => {
    localStorage.clear();
    history('/');
  };

  console.log(user?.groupname, 'groupname');
  

  return (
    <div>
      <nav className="navbar navbar-expand-lg hyphenview_header">
        <div className="container-fluid">
          <img src={Logo} alt="Logo" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className={`nav-item ${activeTab === '/Dashboard' ? 'active' : ''}`} onClick={() => handleTabClick('/Dashboard')}>
                <a className={`nav-link ${activeTab === '/Dashboard' ? 'active' : ''}`} aria-current="page" href="#">
                  Dashboard
                </a>
              </li>

              {userfeature.includes('User Management') && (
                <li className={`nav-item ${activeTab === '/UserManagementList' ? 'active' : ''}`} onClick={() => handleTabClick('/UserManagementList')}>
                  <a className={`nav-link ${activeTab === '/UserManagementList' ? 'active' : ''}`} href="#">
                    User Management
                  </a>
                </li>
              )}
              {userfeature.includes('Report Management') && (
                <li className={`nav-item ${activeTab === '/ListOfReports' ? 'active' : ''}`} onClick={() => handleTabClick('/ListOfReports')}>
                  <a className={`nav-link ${activeTab === '/ListOfReports' ? 'active' : ''}`} href="#">
                    Reports Management
                  </a>
                </li>
              )}
              {userfeature.includes('Report Scheduler') && (
                <li className={`nav-item ${activeTab === '/ReportSchedulerList' ? 'active' : ''}`} onClick={() => handleTabClick('/ReportSchedulerList')}>
                  <a className={`nav-link ${activeTab === '/ReportSchedulerList' ? 'active' : ''}`} href="#">
                    Reports Scheduled
                  </a>
                </li>
              )}
              {userfeature.includes('Dashboard Management') && (
                <li className={`nav-item ${activeTab === '/ListOfDashboardCanvas' ? 'active' : ''}`} onClick={() => handleTabClick('/ListOfDashboardCanvas')}>
                  <a className={`nav-link ${activeTab === '/ListOfDashboardCanvas' ? 'active' : ''}`} href="#">
                    Dashboard Management
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="header__profile_notification">
            <button className="header__profile_blank" onClick={() => dropdown === "none" ? setDropDown("block") : setDropDown("none")}>
              <span><p>{user?.groupname[0]}</p></span>
              <p>{user?.groupname}</p>
            </button>
          </div>
          <div className="header_dropdown" style={{ display: dropdown }} ref={dropdownRef}>
            <p className='log-out'>{user?.groupname}</p>
            <p onClick={logout} className='log-out'>Log out</p>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;

