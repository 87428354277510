export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const AUTH = 'AUTH';
export const LOGIN = 'LOGIN';
export const DB_CONNECTION = 'DB_CONNECTION';
export const VALIDATE_CONNECTION = 'VALIDATE_CONNECTION'; 
export const SCHEMA_DATA = 'SCHEMA_DATA';
export const SET_DIV_COUNT = 'SET_DIV_COUNT';
export const REPORT_TEMPLATE_DATA_SAVE = 'REPORT_TEMPLATE_DATA_SAVE';
export const GET_REPORT_ON_DASHBOARD = 'GET_REPORT_ON_DASHBOARD';
export const GET_REPORT_FORMATE_DATA = 'GET_REPORT_FORMATE_DATA';
export const TEST_QUERY_CUSTOM = 'TEST_QUERY_CUSTOM';
export const CUSTOM_PREVIEW_DATA_TABLE = 'CUSTOM_PREVIEW_DATA_TABLE';
export const CUSTOM_PREVIEW_DATA_CHART = 'CUSTOM_PREVIEW_DATA_CHART';
export const LIST_OF_USER = 'LIST_OF_USER';
export const REMOVE_REPORT = 'REMOVE_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const GENERATE_REPORT_BY_ID = 'GENERATE_REPORT_BY_ID';
export const LIST_OF_GROUP = 'LIST_OF_GROUP';
export const SAVE_USER = 'SAVE_USER';
export const LIST_OF_FEATURE = 'LIST_OF_FEATURE';
export const ADD_GROUP = 'ADD_GROUP';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const DELETE_USER = 'DELETE_USER';
export const ASSIGN_GROUP_ID_TO_USER = 'ASSIGN_GROUP_ID_TO_USER';
export const ASSIGN_REPORT_ID_TO_FEATURE = 'ASSIGN_REPORT_ID_TO_FEATURE';
export const ASSIGN_GROUP_ID_TO_REPORT = 'ASSIGN_GROUP_ID_TO_REPORT';
export const CREATE_CANVAS_PAGE = 'CREATE_CANVAS_PAGE';
export const CANVAS_FRAME_PAGE_DATA='CANVAS_FRAME_PAGE_DATA';
export const GET_REPORT_DETAIL_BASEON_GROUP_ID='GET_REPORT_DETAIL_BASEON_GROUP_ID';
export const GET_REPORT_DETAIL_BY_ID='GET_REPORT_DETAIL_BY_ID';
export const REST_API_DETAIL_SAVE='REST_API_DETAIL_SAVE';
export const LIST_OF_SCHEDULE_REPORT='LIST_OF_SCHEDULE_REPORT';
export const SAVE_SCHEDULE_REPORT='SAVE_SCHEDULE_REPORT';
export const UPDATE_CANVAS_FRAME_PAGE_DATA='UPDATE_CANVAS_FRAME_PAGE_DATA';
export const LIST_ASSIGN_GROUP_ID_TO_FEATURE='LIST_ASSIGN_GROUP_ID_TO_FEATURE';
export const DETAIL_SCHEDULE_REPORT_FOR_UPDATE='DETAIL_SCHEDULE_REPORT_FOR_UPDATE';
export const UPDATE_SCHEDULER='UPDATE_SCHEDULER';
export const REMOVE_SCHEDULER='REMOVE_SCHEDULER';
export const RESET_TEST_QUERY_CUSTOM='RESET_TEST_QUERY_CUSTOM';
export const RESET_DATA_WITH_BACK_BUTTON='RESET_DATA_WITH_BACK_BUTTON';
export const DATA_WITH_BACK_BUTTON = 'DATA_WITH_BACK_BUTTON';
export const GET_REPORTS_ACCESS_GROUP_ID = 'GET_REPORTS_ACCESS_GROUP_ID';
export const GET_REPORT_ACCESS_DETAIL = 'GET_REPORT_ACCESS_DETAIL';
export const GET_CHART_REPORT_DETAIL_BY_ID = 'GET_CHART_REPORT_DETAIL_BY_ID';
export const RESET_MESSAGE_TEST_QUERY_CUSTOM = 'RESET_MESSAGE_TEST_QUERY_CUSTOM';
export const RESET_MESSAGE_GROUP = 'RESET_MESSAGE_GROUP';
export const MESSAGE_ADD_GROUP = 'MESSAGE_ADD_GROUP';
export const CUSTOM_PREVIEW_DATA_BOX = 'CUSTOM_PREVIEW_DATA_BOX';
export const BOX_COLOR_DATA_SAVE = 'BOX_COLOR_DATA_SAVE';
export const GET_BOX_REPORT_DETAIL_BY_ID = 'GET_BOX_REPORT_DETAIL_BY_ID';
export const DELETE_CANVAS_FRAME = 'DELETE_CANVAS_FRAME';
export const DB_CONNECTION_SET_DEFAULT = 'DB_CONNECTION_SET_DEFAULT';
export const RESET_PASSWORD_MESSAGE = 'RESET_PASSWORD_MESSAGE';
export const TEST_QUERY_CUSTOM_MESSAGE_TYPE = 'TEST_QUERY_CUSTOM_MESSAGE_TYPE';
export const LOGIN_MESSAGE = 'LOGIN_MESSAGE';
export const CHECK_DASHBOARD_CANVAS_FRAME = 'CHECK_DASHBOARD_CANVAS_FRAME';
export const TEST_QUERY_CUSTOM_DRILL_DOWN = 'TEST_QUERY_CUSTOM_DRILL_DOWN';
export const RESET_TEST_QUERY_CUSTOM_DRILL_DOWN = 'RESET_TEST_QUERY_CUSTOM_DRILL_DOWN';
export const GET_LIST_OF_COLUMN_FIRST  = 'GET_LIST_OF_COLUMN_FIRST';
export const GET_LIST_OF_COLUMN_SECOND = 'GET_LIST_OF_COLUMN_SECOND';
export const REMOVE_LIST_OF_COLUMN_SECOND = 'REMOVE_LIST_OF_COLUMN_SECOND';
export const REMOVE_LIST_OF_COLUMN_FIRST = 'REMOVE_LIST_OF_COLUMN_FIRST';
export const SAVE_MAP_DATA_FOR_DRILLDOWN = 'SAVE_MAP_DATA_FOR_DRILLDOWN';
export const GET_DRILL_DOWN_DATA = 'GET_DRILL_DOWN_DATA';
export const INITIAL_GET_DRILL_DOWN_DATA = 'INITIAL_GET_DRILL_DOWN_DATA';
export const LIST_DASHBOARD_CANVAS_FRAME_NAME = 'LIST_DASHBOARD_CANVAS_FRAME_NAME';
export const LIST_DASHBOARD_CANVAS_FRAME_ACCESS = 'LIST_DASHBOARD_CANVAS_FRAME_ACCESS';
export const UPDATE_DASHBOARD_ACCESS = 'UPDATE_DASHBOARD_ACCESS';
export const CANVAS_FRAME_PAGE_DATA_FOR_COME_FIRST = 'CANVAS_FRAME_PAGE_DATA_FOR_COME_FIRST';
export const GET_FRAME_PAGE_DATA_BASE_ON_ID = 'GET_FRAME_PAGE_DATA_BASE_ON_ID';
export const LIST_DASHBOARD_CANVAS_FRAME_NAME_WITH = 'LIST_DASHBOARD_CANVAS_FRAME_NAME_WITH';
export const CHECK_DRILL_DOWN = 'CHECK_DRILL_DOWN';
export const  DEFAULT_CHECK_DRILL_DOWN = 'DEFAULT_CHECK_DRILL_DOWN';
export const IS_AUTHORIZE_USER = 'IS_AUTHORIZE_USER';
export const CHAT_BOT_TEXT_TO_QUERY= 'CHAT_BOT_TEXT_TO_QUERY';
export const CHAT_BOT_TEXT_TO_QUERY_RESET = 'CHAT_BOT_TEXT_TO_QUERY_RESET';
export const UPDATE_MAP_DATA_FOR_DRILLDOWN = 'UPDATE_MAP_DATA_FOR_DRILLDOWN';
export const GET_DRILLDOWN_FOR_UPDATE = 'GET_DRILLDOWN_FOR_UPDATE';
export const DEFAULT_GET_DRILLDOWN_FOR_UPDATE = 'DEFAULT_GET_DRILLDOWN_FOR_UPDATE'
export const UPDATE_GET_REPORT_ON_DASHBOARD = 'UPDATE_GET_REPORT_ON_DASHBOARD';
export const ADD_UPDATE_GET_REPORT_ON_DASHBOARD ='ADD_UPDATE_GET_REPORT_ON_DASHBOARD';
export const INITIAL_CHECK_DASHBOARD_CANVAS_FRAME = 'INITIAL_CHECK_DASHBOARD_CANVAS_FRAME';
export const REMOVE_OF_GROUP = 'REMOVE_OF_GROUP';
export const EDIT_OF_GROUP = 'EDIT_OF_GROUP';
export const EDIT_OF_GROUP_MESSAGE = 'EDIT_OF_GROUP_MESSAGE';
export const CHECK_REMOVE_OF_GROUP = 'CHECK_REMOVE_OF_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const MESSAGE_CREATE_CANVAS_PAGE = 'MESSAGE_CREATE_CANVAS_PAGE';
export const UPDATE_CANVAS_FRAME_PAGE_DATA_MESSAGE = 'UPDATE_CANVAS_FRAME_PAGE_DATA_MESSAGE';
export const GET_CHART_REPORT_DETAIL_BY_ID_BEFORE = 'GET_CHART_REPORT_DETAIL_BY_ID_BEFORE';
export const SESSION_LOGOUT = 'SESSION_LOGOUT'

export const ASSIGN_GROUP_ID_TO_DBSOURCE = "ASSIGN_GROUP_ID_TO_DBSOURCE";
export const GET_DB_DETAILS = "GET_DB_DETAILS";



