/*modified by Yuvraj Jaiswal */
import React, { useEffect, useMemo, useState } from "react";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./../globalCSS/usermanagement/usermanagementlist.css";
import "../globalCSS/reportmanagement/listofreports.css";
import { listOfuser, deleteUser } from "../../actions/usermanagement";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "./../Pagination/Pagination";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as XLSX from "xlsx";
import ShowAlert from "../../actions/ShowAlert";
import { decryptData } from '../utils/EncriptionStore';

function UserManagementList() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const apiData = useSelector((state) => state.usermanagement);
  const user = (() => {
    const encryptedData = localStorage.getItem("profile");
    return encryptedData ? decryptData(encryptedData) : null;
  })();


  const requiredValues = ["a", "e", "d", "v"];
  const reportsManagementObject = user.features.find(
    (obj) =>
      obj.featurename === "User Management" && user.group_id === obj.group_id
  );
  const useraccessmask = user.features.filter(
    (item) =>
      item.featurename === "User Management" && user.group_id === item.group_id
  );
  let selectaccessmask;
  if (useraccessmask.length > 0) {
    selectaccessmask = [...useraccessmask[0].accessmask];
  }

  useEffect(() => {
    const editButtons = document.getElementsByClassName("fa-pen-to-square");
    const removeButtons = document.getElementsByClassName("fa-trash-can");
    const addingnewuser = document.getElementsByClassName("adding_new_user");
    const addingnewuserntothegroup = ["a"].every((value) =>
      selectaccessmask.includes(value)
    );
    const allValuesExist = ["e"].every((value) =>
      selectaccessmask.includes(value)
    );
    const allValuesExist3 = ["d"].every((value) =>
      selectaccessmask.includes(value)
    );

    const updateButtonStyles = (
      buttons,
      condition,
      disabledClass,
      enabledClass
    ) => {
      Array.from(buttons).forEach((button) => {
        if (!condition) {
          button.style.pointerEvents = "none";
          button.style.color = "grey"; // Hide the button if access mask does not have required value
          button.style.display = "none"; // Hide the button
          button.classList.add(disabledClass);
        } else {
          button.style.pointerEvents = "auto"; // Enable pointer events
          button.style.display = "inline"; // Show the button
          button.classList.add(enabledClass);
        }
      });
    };

    updateButtonStyles(editButtons, allValuesExist, "edit-button-disabled", "edit-button-enabled");

    updateButtonStyles(addingnewuser, addingnewuserntothegroup, "edit-button-disabled", "edit-button-enabled");

    updateButtonStyles(removeButtons, allValuesExist3, "edit-button-disabled", "edit-button-enabled");

  }, [selectaccessmask]);

  const handelclickgotoDashboard = () => {
    history("/Dashboard");
  };
  useEffect(() => {
    setCurrentPage(1); // Reset to the first page when search term changes
  }, [search]);

  const handelclickAddNewReport = () => {
    history("/NewUser");
  };

  useEffect(() => {
    dispatch(
      listOfuser({ email: user.user_email_id, database_type: "mysql" }, history)
    );
  }, []);

  
    const listofalluser = 
      Array.isArray(apiData?.allUserDetail) && apiData?.allUserDetail.length > 0
    ? apiData?.allUserDetail.some((item) => item.groupname === "SuperAdmin" && user.groupname === "SuperAdmin") // Check if any item matches user.groupname
      ? apiData?.allUserDetail // If a match is found, leave the array unchanged
      : apiData?.allUserDetail.filter((item) => item.groupname !== "SuperAdmin") // Otherwise, filter out items with groupname "SuperAdmin"
    : [];

  let PageSize = 5;

  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = useMemo(() => {
    if (!search) return listofalluser;
    return listofalluser?.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, listofalluser]);

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredData, PageSize]);

  const handelclicktoAssignation = () => {
    // history('/AssignationAndFeature')
    history("/FeatureAssignpage");
  };

  const handelclickGroupupdate = () => {
    history("/UpdateGroup");
  };
  const handelclickGroupManagement = () => {
    history("/GroupManagement");
  };

  const handelclickGroupSourceManagement = () =>{
      history('/datasourceaccess')
    }

  const handelclick = async (e) => {
    try {
      const userConfirmed = await ShowAlert({
        title: "Confirmation",
        message: "Are you sure you want to delete this user?",
        options: ["OK", "Cancel"],
      });
      if (userConfirmed === "OK") {
        dispatch(deleteUser({ email: e, database_type: "mysql" }, history))
          .then(() => {
            const remainingReports = filteredData.length - 1;
            const lastPageIndex = (currentPage - 1) * PageSize;
            if (remainingReports <= lastPageIndex && currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          })
          .catch((error) => {
            console.error("Error removing report:", error);
          });
      } else {
        console.log("User canceled the operation.");
      }
    } catch (error) {
      console.error("Error removing user:", error);
    }
  };

  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(listofalluser);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ListofUsers.xlsx");
  };

  return (
    <div>
      <div className="Header">
        <Header />
      </div>
      <div className="user_management_page">
        <span
          class="fas fa-house-user"
          aria-hidden="true"
          onClick={handelclickgotoDashboard}
        ></span>
        <span>/</span>
        <span> User Management </span>
        {requiredValues.every((value) =>
          reportsManagementObject.accessmask.includes(value)
        ) ? (
          <div class="dropdown hyphenview_manage_users_dropdown">
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  border: "none",
                  fontSize: "0.875rem !important",
                  marginLeft: "5px",
                }}
                id="dropdown-basic"
              >
                User Action
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {requiredValues.every((value) =>
                  reportsManagementObject.accessmask.includes(value)
                ) ? (
                  <Dropdown.Item
                    onClick={handelclicktoAssignation}
                    id="accessFeatureId"
                    href="#"
                  >
                    Access Features
                  </Dropdown.Item>
                ) : null}
                {requiredValues.every((value) =>
                  reportsManagementObject.accessmask.includes(value)
                ) ? (
                  <Dropdown.Item
                    onClick={handelclickAddNewReport}
                    id="newUserId"
                    href="#"
                  >
                    Add New User
                  </Dropdown.Item>
                ) : null}
                {requiredValues.every((value) =>
                  reportsManagementObject.accessmask.includes(value)
                ) ? (
                  <Dropdown.Item
                    onClick={handelclickGroupupdate}
                    id="groupAssignationId"
                    href="#"
                  >
                    Group Assignation
                  </Dropdown.Item>
                ) : null}
                {requiredValues.every((value) =>
                  reportsManagementObject.accessmask.includes(value)
                ) ? (
                  <Dropdown.Item
                    onClick={handelclickGroupManagement}
                    id="groupManagementId"
                    href="#"
                  >
                    Group Management
                  </Dropdown.Item>
                ) : null}
                {requiredValues.every(value => reportsManagementObject.accessmask.includes(value)) && (user.groupname === "SuperAdmin")
                ? <Dropdown.Item onClick={handelclickGroupSourceManagement} id="datasourceaccess" href="#" >Source Management</Dropdown.Item> : null}

              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
      </div>
      <div className="user_management_table_container">
        <div className="table-top">
          <div className="right-side-elements hyphenview_download_and_search_container">
            <div className="download-container hyphenview_download_button_container">
              <button
                className="download-btn hyphenview_download-button"
                onClick={exportExcel}
              >
                <i className="fas fa-download"></i>
                <span className="download-text"></span>
              </button>
            </div>
            <div className="search-container hyphenview_search">
              <input
                type="text"
                placeholder="Search"
                value={search}
                maxLength={120}
                onChange={(e) => setSearch(e.target.value)}
                className="search-box"
              />
              <svg
                className="search-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                width="16"
                height="16"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="8"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <line
                  x1="16"
                  y1="16"
                  x2="21"
                  y2="21"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="user_management_table_content">
          <table
            id="table-to-excel"
            className="responsive-table user_management_table"
            style={{ width: "100%" }}
          >
            <thead>
              <tr className="table-header">
                <th>User Email Id</th>
                <th>Group Name</th>
                <th>Status</th>
                <th>Created Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData &&
                paginatedData?.map((userdata, index) => (
                  <tr key={index}>
                    <td>{userdata.user_email_id}</td>
                    <td>{userdata.groupname}</td>
                    <td>{userdata.user_status}</td>
                    <td>{userdata.user_creation_date.replace("T", " ")}</td>
                    <td>
                      <Link
                        to={`/ResetPassword?user_email_id=${userdata.user_email_id}`}
                        className="fa-solid fa-pen-to-square edit-button"
                      >
                        <span
                          style={{ fontSize: "15px", marginLeft: "3px" }}
                        ></span>
                      </Link>

                      <i
                        style={{ color: "#0d6efd", cursor: "pointer" }}
                        className="fa-solid fa-trash-can delete-button"
                        onClick={() => handelclick(userdata.user_email_id)}
                      ></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="user_management_list_pagination hypehnview_manage_users_page">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filteredData ? filteredData.length : 1}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}

export default UserManagementList;
