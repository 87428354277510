const CloseIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" {...props}>
			<path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256l105.3-105.4z" />
		</svg>
	);
};

const EditIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" {...props}>
			<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h293.1c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1h-91.3zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM375.9 417c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5.2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4l129.2-129.3-71-71L375.9 417z" />
		</svg>
	);
};

const InfoIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm-40-176h24v-64h-24c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-80c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z" />
		</svg>
	);
};

const FacebookIcon = (props) => {
	return (
		<svg
			width={22}
			height={21}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.911 0H5.702C2.874 0 .565 2.31.565 5.141v10.233c0 2.815 2.309 5.125 5.137 5.125h4.6v-8.191H7.557V9.102h2.747v-2.36c0-2.737 1.67-4.225 4.109-4.225 1.166 0 2.17.085 2.462.126v2.859h-1.682c-1.325 0-1.585.63-1.585 1.558v2.042h3.174l-.415 3.204h-2.76V20.5h2.322c2.828 0 5.136-2.31 5.136-5.141V5.141C21.048 2.311 18.74 0 15.911 0Z"
				fill="#fff"
			/>
		</svg>
	);
};

const TwitterIcon = (props) => {
	return (
		<svg
			width={21}
			height={21}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.67 0H5.461C2.633 0 .324 2.31.324 5.141v10.233c0 2.815 2.309 5.125 5.137 5.125h10.225c2.829 0 5.137-2.31 5.137-5.141V5.141C20.807 2.311 18.5 0 15.67 0Zm-.016 7.679v.342c0 3.416-2.634 7.353-7.462 7.353a7.48 7.48 0 0 1-4.015-1.155c.211.016.406.033.618.033a5.233 5.233 0 0 0 3.25-1.107 2.62 2.62 0 0 1-2.454-1.79c.163.033.325.05.488.05.244 0 .488-.033.699-.098a2.6 2.6 0 0 1-2.065-2.538v-.033c.326.196.748.31 1.17.326a2.574 2.574 0 0 1-1.17-2.148c0-.471.13-.91.358-1.301a7.508 7.508 0 0 0 5.397 2.7 2.396 2.396 0 0 1-.065-.585c0-1.432 1.17-2.587 2.617-2.587.748 0 1.43.31 1.918.813a4.877 4.877 0 0 0 1.659-.634 2.648 2.648 0 0 1-1.155 1.432 5.191 5.191 0 0 0 1.512-.407c-.357.52-.796.976-1.3 1.334Z"
				fill="#fff"
			/>
		</svg>
	);
};

const LinkedinIcon = (props) => {
	return (
		<svg
			width={21}
			height={21}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.346 0H5.136C2.309 0 0 2.31 0 5.141v10.233C0 18.19 2.308 20.5 5.137 20.5h10.225c2.829 0 5.137-2.31 5.137-5.141V5.141C20.483 2.311 18.174 0 15.346 0ZM7.023 15.11H4.49V7.484h2.532v7.626ZM5.755 6.443h-.017c-.849 0-1.402-.586-1.402-1.318 0-.749.565-1.318 1.435-1.318.87 0 1.402.57 1.418 1.318 0 .732-.548 1.318-1.434 1.318Zm10.404 8.667h-2.532v-4.08c0-1.024-.366-1.724-1.285-1.724-.699 0-1.117.472-1.3.927-.065.163-.085.39-.085.618v4.263H8.425s.032-6.914 0-7.63h2.532v1.082c.337-.521.938-1.261 2.284-1.261 1.666 0 2.918 1.09 2.918 3.433v4.372Z"
				fill="#fff"
			/>
		</svg>
	);
};

const BellIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" {...props}>
			<path d="M224 0c-17.7 0-32 14.3-32 32v19.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416h384c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3c-31.2-35.2-48.5-80.5-48.5-127.6V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H160c0 17 6.7 33.3 18.7 45.3S207 512 224 512s33.3-6.7 45.3-18.7z" />
		</svg>
	);
};

const NotificationIcon = (props) => {
	return (
		<svg
			width={15}
			height={16}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.008 13.166a2.574 2.574 0 1 1-5.148 0 2.574 2.574 0 0 1 5.148 0Z"
				fill="#fff"
			/>
			<path
				d="M10.944 7.968a4.507 4.507 0 0 1-3.866-4.454c0-.643.138-1.254.381-1.808a4.485 4.485 0 0 0-1.025-.122A4.509 4.509 0 0 0 1.93 6.088v1.794a4.315 4.315 0 0 1-1.536 3.303 1.127 1.127 0 0 0 .732 1.981h10.617a1.127 1.127 0 0 0 .726-1.987 4.308 4.308 0 0 1-1.525-3.211Z"
				fill="#fff"
			/>
			<path
				d="M14.799 3.514a3.217 3.217 0 1 1-6.435 0 3.217 3.217 0 0 1 6.435 0Z"
				fill="#F05D46"
			/>
		</svg>
	);
};

const PageIcon = (props) => {
	return (
		<svg
			width={15}
			height={18}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M14.514 3.592h.01V1.67c0-.731-.646-1.328-1.44-1.328H1.663C.869.342.223.937.223 1.67v14.191c0 .732.646 1.329 1.44 1.329h11.42c.795 0 1.44-.595 1.44-1.33V13.76c0-4.312-.01-3.02-.01-10.17ZM7.83 13.148a.648.648 0 0 1-.459.19h-4.55a.648.648 0 1 1 0-1.3h4.55a.648.648 0 0 1 .459 1.109Zm0-3.89a.648.648 0 0 1-.459.191h-4.55a.648.648 0 1 1 0-1.3h4.55a.648.648 0 0 1 .459 1.109ZM2.822 5.54a.648.648 0 1 1 0-1.3h7.8a.648.648 0 1 1 0 1.3h-7.8Z"
				fill="#092433"
			/>
		</svg>
	);
};

const ReadingBook = (props) => {
	return (
		<svg
			width={18}
			height={18}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#a)" fill="#092433">
				<path d="M8.562 6.048a2.844 2.844 0 1 0 0-5.687 2.844 2.844 0 0 0 0 5.687ZM10.652 6.432a3.825 3.825 0 0 1-2.089.618c-.77 0-1.487-.227-2.09-.618a5.849 5.849 0 0 0-1.819 1.11L8.563 9.01l3.909-1.466a5.849 5.849 0 0 0-1.82-1.111ZM14.586 10.732h-.334a.502.502 0 0 0-.502.502v1.338c0 .278.225.502.502.502h.334a1.17 1.17 0 1 0 0-2.342ZM2.927 8.197a.502.502 0 0 0-.216.412v1.117h.167c.83 0 1.506.676 1.506 1.506v1.338c0 .83-.676 1.505-1.506 1.505h-.167v1.058c0 .209.13.396.326.47l5.027 1.885V9.892L3.389 8.14a.502.502 0 0 0-.462.058Z" />
				<path d="M12.746 12.57v-1.338c0-.83.676-1.506 1.506-1.506h.167V8.61a.502.502 0 0 0-.678-.47L9.066 9.892v7.596l5.027-1.885a.502.502 0 0 0 .326-.47v-1.058h-.167c-.83 0-1.506-.675-1.506-1.505ZM3.378 12.572v-1.338a.502.502 0 0 0-.502-.502h-.334a1.17 1.17 0 1 0 0 2.342h.335a.502.502 0 0 0 .501-.502Z" />
			</g>
			<defs>
				<clipPath id="a">
					<path
						fill="#fff"
						transform="translate(0 .363)"
						d="M0 0h17.128v17.128H0z"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

const SunriseIcon = (props) => {
	return (
		<svg
			width={18}
			height={17}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g
				clipPath="url(#a)"
				stroke="#092433"
				strokeWidth={2.447}
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<path d="M12.133 13.676a3.568 3.568 0 0 0-7.137 0M8.564 2.256v4.996M3.012 8.123l1.013 1.013M.713 13.676H2.14M14.986 13.676h1.428M13.104 9.136l1.013-1.013M16.414 16.531H.713M5.71 5.11l2.856-2.854L11.42 5.11" />
			</g>
			<defs>
				<clipPath id="a">
					<path
						fill="#fff"
						transform="translate(0 .83)"
						d="M0 0h17.128v17.128H0z"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

const RiskAssessment = (props) => {
	return (
		<svg
			width={21}
			height={22}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="m10.808 12.983 2.896-5.697a2.178 2.178 0 0 1 1.946-1.204h.01V4.16c0-.73-.646-1.328-1.44-1.328H2.8c-.795 0-1.44.595-1.44 1.328v14.192c0 .73.645 1.328 1.44 1.328h11.42c.794 0 1.44-.595 1.44-1.33v-2.098h-2.906a2.175 2.175 0 0 1-1.852-1.04l-.002-.004-.002-.005a2.276 2.276 0 0 1-.09-2.22Zm-1.84 2.655a.648.648 0 0 1-.46.191H3.96a.648.648 0 1 1 0-1.3h4.55a.648.648 0 0 1 .459 1.109Zm0-3.89a.648.648 0 0 1-.46.192H3.96a.648.648 0 1 1 0-1.3h4.55a.648.648 0 0 1 .459 1.109ZM3.959 8.03a.648.648 0 1 1 0-1.3h7.8a.648.648 0 1 1 0 1.3h-7.8Z"
				fill="#092433"
			/>
			<path
				d="m19.33 13.57-2.895-5.696a.886.886 0 0 0-.786-.495.884.884 0 0 0-.786.496l-2.895 5.695a.985.985 0 0 0 .039.954.88.88 0 0 0 .747.427h5.789c.305 0 .585-.16.751-.427a.99.99 0 0 0 .037-.954ZM15 10.354a.648.648 0 0 1 1.295 0v1.288a.648.648 0 0 1-1.295 0v-1.287Zm.647 3.48a.65.65 0 1 1 .003-1.299.65.65 0 0 1-.002 1.299Z"
				fill="#092433"
			/>
		</svg>
	);
};

const PenIcon = (props) => {
	return (
		<svg
			width={20}
			height={21}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.16 2.965 7.918 7.748l1.871 1.34 2.203 1.47 1.667-1.306 2.133-2.49-5.632-3.797Z"
				fill="#F0F7FC"
			/>
			<path
				d="m15.884 6.16-5.082-3.506a.828.828 0 0 0-1.233.36L7.296 8.406a1.331 1.331 0 0 1-.368.501l-1.664 1.4a2.337 2.337 0 0 0-.825 1.88l.175 4.004-.718 1.04a.693.693 0 0 0-.377 1.152.7.7 0 0 0 .444.214v.006h8.571v-.985H4.824l.6-.87 3.804-1.255a2.341 2.341 0 0 0 1.466-1.442l.717-2.05c.07-.198.186-.377.337-.523l4.236-4.037a.828.828 0 0 0-.1-1.28Zm-5.402 5.515-.717 2.053c-.135.39-.453.702-.845.832l-2.569.849 2.52-3.652a.693.693 0 1 0-.81-.558l-2.52 3.652-.119-2.704c-.017-.412.16-.818.476-1.085l1.663-1.4c.28-.235.501-.535.643-.871l.091-.22 2.896 2.078-.124.118c-.262.25-.464.564-.585.908Zm1.436-1.718L8.689 7.64l1.713-4.066 4.764 3.288-3.248 3.095Z"
				fill="#092433"
			/>
		</svg>
	);
};
const PencilIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 32 32">
			<path
				d="M22.883 3.8c-.39.001-.776.142-1.059.425l-1.4 1.4-1.428 1.428L6.266 19.78c-.027.027-.04.06-.06.092a.496.496 0 0 0-.24.29L4.024 26.35a.502.502 0 0 0 .631.627l6.274-2.034a.494.494 0 0 0 .236-.173c.017-.013.037-.02.053-.036l12.728-12.728 2.828-2.83a1.504 1.504 0 0 0 0-2.123l-2.828-2.828a1.497 1.497 0 0 0-1.062-.424zm0 .991c.13 0 .26.046.355.14l2.828 2.829a.5.5 0 0 1 0 .707L25.02 9.514l-3.536-3.535 1.047-1.047a.492.492 0 0 1 .352-.141zm-2.106 1.895 3.535 3.535-.72.722-1.768-1.77-1.77-1.765.723-.722zm-1.43 1.43.708.706L8.584 20.293l-1.15-.266L19.348 8.115zm1.415 1.413.355.354.354.353L10 21.706l-.758.03v-.687l11.52-11.52zm1.414 1.414.707.707-11.836 11.836-.28-1.136 11.409-11.407zM6.78 20.903l1.461.337v1.016a.5.5 0 0 0 .52.5l1.064-.043.352 1.42-2.37.767a.478.478 0 0 0-.113-.183l-1.414-1.414a.487.487 0 0 0-.215-.121l.715-2.28zM5.75 24.185l1.045 1.044-1.53.495.485-1.54z"
				{...props}
			/>
		</svg>
	);
};

const ArrowLeft = (props) => {
	return (
		<svg
			width={6}
			height={10}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M5 9 1 5l4-4"
				stroke="#1F1C47"
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const DownArrow = (props) => {
	return (
		<svg
			width={11}
			height={7}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M1.682 1 5.84 5.518 9.999 1"
				stroke="#000"
				strokeWidth={1.386}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const CalenderIcon = (props) => {
	return (
		<svg
			width={16}
			height={18}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.351 2.334H2.544C1.69 2.334 1 3.064 1 3.964V15.37c0 .9.691 1.63 1.544 1.63H13.35c.853 0 1.544-.73 1.544-1.63V3.964c0-.9-.691-1.63-1.544-1.63Z"
				stroke="#9E9E9E"
				{...props}
				strokeWidth={1.619}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.264 1v3.38M5.63 1v3.38M2.738 7.762H13.16"
				stroke="#9E9E9E"
				{...props}
				strokeWidth={1.158}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const SearchIcon = (props) => {
	return (
		<svg
			width={21}
			height={21}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.625 16.625a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM18.373 18.373l-3.807-3.807"
				stroke="#fff"
				strokeWidth={1.75}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const EditPencil = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			<path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0l-30.1 30 97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2l-18.7-18.6zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5l167.3-167.4-98-98-167.3 167.4zM96 64c-53 0-96 43-96 96v256c0 53 43 96 96 96h256c53 0 96-43 96-96v-96c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
		</svg>
	);
};

const SaveIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" {...props}>
			<path d="M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7c-12-12-28.3-18.7-45.3-18.7H64zm0 96c0-17.7 14.3-32 32-32h192c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32v-64zm160 288c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64z" />
		</svg>
	);
};
const DeleteIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			
			viewBox="0 0 29 29"
			{...props}
			// xml:space="preserve"
		>
			<path d="M10 3v3h9V3a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1z" />
			<path d="M4 5v1h21V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1zM6 8l1.812 17.209A2 2 0 0 0 9.801 27H19.2a2 2 0 0 0 1.989-1.791L23 8H6zm4.577 16.997a.999.999 0 0 1-1.074-.92l-1-13a1 1 0 0 1 .92-1.074.989.989 0 0 1 1.074.92l1 13a1 1 0 0 1-.92 1.074zM15.5 24a1 1 0 0 1-2 0V11a1 1 0 0 1 2 0v13zm3.997.077a.999.999 0 1 1-1.994-.154l1-13a.985.985 0 0 1 1.074-.92 1 1 0 0 1 .92 1.074l-1 13z" />
		</svg>
	);
};
export {
	CloseIcon,
	EditIcon,
	InfoIcon,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	BellIcon,
	NotificationIcon,
	PageIcon,
	ReadingBook,
	SunriseIcon,
	RiskAssessment,
	PenIcon,
	ArrowLeft,
	DownArrow,
	CalenderIcon,
	SearchIcon,
	EditPencil,
	SaveIcon,
	PencilIcon,
	DeleteIcon,
};
