//modified By Yuvraj Jaiswal
//modified stackbar and stackcolumn configuration
import React, { useEffect, useMemo, useState } from "react";
import HighChartsColors from "./HIghChartsColors";
import { useDispatch, useSelector } from "react-redux";
import { customPreviewChartData } from "../../actions/auth";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import highchartsOfflineExporting from "highcharts/modules/offline-exporting";
import Highcharts3D from "highcharts/highcharts-3d";
import HighchartsMap from "highcharts/modules/map";
import SolidGauge from "highcharts/modules/solid-gauge";
import { Offcanvas } from "react-bootstrap";
import drawOnChart from "./../../assets/images/drawOnChart.png";
import HighchartsBoost from "highcharts/modules/boost";
import { decryptData } from "../utils/EncriptionStore";
import { Box, CircularProgress } from "@mui/material";

HighchartsBoost(Highcharts);
Highcharts3D(Highcharts);
HighchartsMap(Highcharts);
HighchartsMore(Highcharts);
highchartsExporting(Highcharts);
highchartsOfflineExporting(Highcharts);
SolidGauge(Highcharts);

const PreviewHighchart = ({
  customizationOptionsPreview,
  setCustomizationOptionsPreview,
  dragMe,
  setDragMe,
}) => {
  const containerId = "highcharts-container";
  const dispatch = useDispatch();

  const user = (() => {
    const encryptedData = localStorage.getItem("profile");
    return encryptedData ? decryptData(encryptedData) : null;
  })();

  const [topology, setTopology] = useState(null);
  const [legends, setLegends] = useState([]);
  const [legendsFromApi, setLegendsFromApi] = useState([]);
  const [totalLegends, setTotalLegends] = useState([]);

  const apiData = useSelector((state) => state?.auth);

  const [columnCount, setcolumnCount] = useState(0);
  const [defaultColors, setDefaultColors] = useState([]);
  const [loading, setLoading] = useState(false);

  const CustomeDetailOfReport = JSON.parse(
    localStorage.getItem("customeDetailOfReport")
  );


  const PreviewchartData = apiData?.custom_preview_chart;

  useEffect(() => {
    const fetchReport = async () => {
      setLoading(true); // Show loader
      await dispatch(
        customPreviewChartData({
          report_name: CustomeDetailOfReport?.title,
          report_type: CustomeDetailOfReport?.type,
          chart_type: CustomeDetailOfReport?.chart_type,
          query: CustomeDetailOfReport?.query,
          email: user.user_email_id,
          database_type: "mysql",
          connection_type: CustomeDetailOfReport?.connection_type,
          schema: CustomeDetailOfReport?.schema,
        })
      );
      setLoading(false); // Hide loader after API call
    };

    fetchReport();
  }, []);

  //to set legends from data already stored in database
  useMemo(() => {
    if (CustomeDetailOfReport?.chart_colours) {
      let apiLegends = Object.keys(CustomeDetailOfReport.chart_colours);
      setLegendsFromApi(apiLegends);
    }
  }, []);

  //to set Column count based on query data
  useMemo(() => {
    if (
      PreviewchartData?.len_col &&
      (CustomeDetailOfReport?.chart_type === "bar" ||
        CustomeDetailOfReport?.chart_type === "column" ||
        CustomeDetailOfReport?.chart_type === "stackbar" ||
        CustomeDetailOfReport?.chart_type === "stackcolumn")
    ) {
      setcolumnCount(PreviewchartData?.len_col);
    }
  }, [PreviewchartData]);

  //to set legends based on columns length returned by query
  useMemo(() => {
    setLegends([]);
    if (
      CustomeDetailOfReport?.chart_type === "3Dpie" ||
      CustomeDetailOfReport?.chart_type === "3D Donut" ||
      CustomeDetailOfReport?.chart_type === "pie" ||
      CustomeDetailOfReport?.chart_type === "donut" ||
      (CustomeDetailOfReport?.chart_type === "bar" && columnCount <= 2) ||
      (CustomeDetailOfReport?.chart_type === "column" && columnCount <= 2) ||
      (CustomeDetailOfReport?.chart_type === "stackbar" && columnCount <= 2) ||
      (CustomeDetailOfReport?.chart_type === "stackcolumn" && columnCount <= 2)
    ) {
      if (
        PreviewchartData?.xAxis &&
        Array.isArray(PreviewchartData.xAxis) &&
        PreviewchartData.xAxis.length > 0
      ) {
        PreviewchartData.xAxis.forEach((item) => {
          // Iterate through categories and add to legends
          item?.categories?.forEach((category) => {
            setLegends((prevLegends) => {
               const nameAsString = String(category)
              // Only add category if it's not already in legends
              if (!prevLegends.includes(nameAsString)) {
                return [...prevLegends, nameAsString];
              }
              return prevLegends;
            });
          });
        });
      }
    } else {
      if (PreviewchartData?.series && Array.isArray(PreviewchartData.series)) {
        PreviewchartData.series
          .filter((series, index) => {
            if (index === 0) {
              return !series.data?.every((item) => typeof item === "string");
            }
            return true;
          })
          .forEach((seriesItem) => {
            setLegends((prevLegends) => {
             const nameAsString = String(seriesItem.name)     
              if (!prevLegends.includes(nameAsString)) {
                return [...prevLegends, nameAsString];
              }

              return prevLegends;
            });
          });
      }
    }
  }, [PreviewchartData, columnCount]);

  useMemo(() => {
    const allLegends = Array.from(new Set([...legends, ...legendsFromApi]));
    setTotalLegends(allLegends);
  }, [legends, legendsFromApi]);



  //to set default colors for legends
  useEffect(() => {
    //to get random colors from HighChartsColors.js
    const getRandomColors = (colors, count) => {
      let shuffledColors = [...colors].sort(() => 0.5 - Math.random());
      return shuffledColors.slice(0, count);
    };

    //to set default colors for legends
    let defaultColorsForJson =
      Array.isArray(totalLegends) && totalLegends?.length > 0
        ? getRandomColors(Object.values(HighChartsColors), totalLegends.length)
        : [];

    setDefaultColors(defaultColorsForJson);
  }, [totalLegends]);



  const [chartColoursFromJson, setChartColoursFromJson] =
    useState(defaultColors);

  //to load report data based on report name

  useEffect(() => {
    if (CustomeDetailOfReport?.chart_type === "geomap") {
      const fetchTopology = async () => {
        const response = await fetch(
          "https://code.highcharts.com/mapdata/countries/in/custom/in-all-disputed.topo.json"
        );
        const topologyData = await response.json();
        setTopology(topologyData);
      };
      fetchTopology();
    }
  }, []);

  //to extract base legend key before bracket
  const getBaseLegendKey = (legend) => {
    const index = legend?.toString().indexOf("(");
    return index !== -1 ? legend.substring(0, index).trim() : legend;
  };

  //to map colors to each legend
  useMemo(() => {
    //function to map colors to legends
    const mapColorsToLegends = (chart_colours) => {
      const colorMapping = {};
      const baseColorMapping = {};

      //to set color for each legend
      totalLegends?.forEach((key) => {
        //obtain base key for each legend
        const baseKey = getBaseLegendKey(key);

        //if legend present as key in chart_colours object
        if (chart_colours[key]) {
          colorMapping[key] = chart_colours[key];
        }

        //if a legend with  similar base key is not present in chart_colours object
        if (!baseColorMapping[baseKey]) {
          baseColorMapping[baseKey] = chart_colours[key];
        }
      });

      return totalLegends.map((legend, index) => {
        //if exact legend is present in chart_colours object
        if (colorMapping[legend]) {
          return colorMapping[legend];
        }
        //if one legend has similar base key as other legend
        const baseKey = getBaseLegendKey(legend);
        if (baseColorMapping[baseKey]) {
          return baseColorMapping[baseKey];
        }
        //if no legend is present in chart_colours object
        return defaultColors[index % defaultColors.length];
      });
    };

    if (customizationOptionsPreview?.chart_colours) {
      setChartColoursFromJson(
        mapColorsToLegends(customizationOptionsPreview.chart_colours)
      );
    } else if (CustomeDetailOfReport?.chart_colours) {
      setChartColoursFromJson(
        mapColorsToLegends(CustomeDetailOfReport.chart_colours)
      );
    }
  }, [customizationOptionsPreview.chart_colours, defaultColors, totalLegends]);




  function mapLegendsToColors(legends, customColors, defaultColors) {
    const colorMapping = {};
    const prefixColorMap = {};

    // Iterate through each legend
    legends.forEach(legend => {
      // Extract the prefix (assuming prefix is everything before an underscore)
      const prefix = legend.split('(')[0];

      // Check if there is a color defined in customColors for this legend
      if (customColors && customColors[legend]) {
        // Assign the defined color to this legend and its prefixes
        colorMapping[legend] = customColors[legend];
        prefixColorMap[prefix] = customColors[legend]; // Store the color for the prefix
      } else if (prefixColorMap[prefix]) {
        // If no specific color, use the prefix's color if it exists
        colorMapping[legend] = prefixColorMap[prefix];
      } else {
        // If no colors defined, assign a default color based on index
        const defaultColorIndex = Object.keys(colorMapping).length % defaultColors.length;
        colorMapping[legend] = defaultColors[defaultColorIndex];
      }
    });

    return colorMapping;
  }

  useEffect(() => {
    setCustomizationOptionsPreview({
      ...customizationOptionsPreview,
      chart_colours: mapLegendsToColors(totalLegends, CustomeDetailOfReport?.chart_colours, defaultColors),
    })
  }, [totalLegends, defaultColors]);



  const chartOptions = useMemo(() => {
    if (!CustomeDetailOfReport || !PreviewchartData) return {};
    const { chart_type } = CustomeDetailOfReport;

    let options = {};
    switch (chart_type) {
      case "line":
        options = {
          chart: { type: "line" },
          title: { text: CustomeDetailOfReport.title || "" },
          yAxis: [
            { title: { text: null } },
            { opposite: true, title: { text: null } },
          ],

          plotOptions: {
            line: {
              marker: {
                enabled: false,
                symbol: "circle",
                radius: 2,
                states: { hover: { enabled: true } },
              },
            },
          },
          xAxis: { categories: PreviewchartData.xAxis[0].categories },
          credits: { enabled: false },
          series: PreviewchartData?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data?.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,
              color:
                chartColoursFromJson[index % chartColoursFromJson.length] ||
                defaultColors[index % defaultColors.length],
            })),
        };
        break;
      case "area":
        options = {
          chart: { type: "area" },
          title: { text: CustomeDetailOfReport.title || "" },
          yAxis: [
            { title: { text: null } },
            { opposite: true, title: { text: null } },
          ],
          xAxis: { categories: PreviewchartData.xAxis[0].categories },
          plotOptions: {
            area: {
              marker: {
                enabled: true,
                states: { hover: { enabled: true } },
              },
            },
          },
          credits: { enabled: false },
          series: PreviewchartData?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data?.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,
              color:
                chartColoursFromJson[index % chartColoursFromJson.length] ||
                defaultColors[index % defaultColors.length],
            })),
        };
        break;
      case "bar":
        console.log("columnCount" + columnCount);
        if (columnCount > 2) {
          options = {
            chart: { type: "bar" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: null } },
              { opposite: true, title: { text: null } },
            ],
            xAxis: { categories: PreviewchartData?.xAxis[0]?.categories || [] },
            credits: { enabled: false },
            series: PreviewchartData?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data?.every(
                    (item) => typeof item === "string"
                  );
                }
                return true;
              })
              .map((series, index) => ({
                boostThreshold: 1000,
                name: series.name,
                data: series.data,
                color:
                  chartColoursFromJson[index % chartColoursFromJson.length] ||
                  defaultColors[index % defaultColors.length],
              })),
          };
        } else if (columnCount <= 2) {
          options = {
            chart: { type: "bar" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: null } },
              { opposite: true, title: { text: null } },
            ],
            xAxis: { categories: PreviewchartData?.xAxis[0]?.categories || [] },
            credits: { enabled: false },
            series: PreviewchartData?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data?.every(
                    (item) => typeof item === "string"
                  );
                }
                return true;
              })
              .map((series, index) => {
                const categoryColors =
                  PreviewchartData?.xAxis[0]?.categories.map(
                    (category, catIndex) =>
                      chartColoursFromJson[
                      catIndex % chartColoursFromJson.length
                      ]
                  );

                return {
                  boostThreshold: 1000,
                  name: series.name,
                  data: series.data.map((dataPoint, pointIndex) => ({
                    y: dataPoint,
                    color:
                      categoryColors[pointIndex % categoryColors.length] ||
                      defaultColors[pointIndex % defaultColors.length],
                  })),
                };
              }),
          };
        }
        break;
      case "column":
        if (columnCount > 2) {
          options = {
            chart: { type: "column" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: null } },
              { opposite: true, title: { text: null } },
            ],
            xAxis: { categories: PreviewchartData?.xAxis[0]?.categories || [] },
            credits: { enabled: false },
            plotOptions: {
              series: {
                colorByPoint: false,
              },
            },
            series: PreviewchartData?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data?.every(
                    (item) => typeof item === "string"
                  );
                }
                return true;
              })
              .map((series, index) => ({
                boostThreshold: 1000,
                name: series.name,
                data: series.data,
                color:
                  chartColoursFromJson[index % chartColoursFromJson.length] ||
                  defaultColors[index % defaultColors.length],
              })),
          };
        } else if (columnCount <= 2) {
          options = {
            chart: { type: "column" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: null } },
              { opposite: true, title: { text: null } },
            ],
            xAxis: { categories: PreviewchartData?.xAxis[0]?.categories || [] },
            credits: { enabled: false },
            series: PreviewchartData?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data?.every(
                    (item) => typeof item === "string"
                  );
                }
                return true;
              })
              .map((series, index) => {
                const categoryColors =
                  PreviewchartData?.xAxis[0]?.categories.map(
                    (category, catIndex) =>
                      chartColoursFromJson[
                      catIndex % chartColoursFromJson.length
                      ]
                  );

                return {
                  boostThreshold: 1000,
                  name: series.name,
                  data: series.data.map((dataPoint, pointIndex) => ({
                    y: dataPoint,
                    color:
                      categoryColors[pointIndex % categoryColors.length] ||
                      defaultColors[pointIndex % defaultColors.length],
                  })),
                };
              }),
          };
        }
        break;
      case "3Dpie":
        options = {
          chart: {
            type: "pie",
            options3d: { enabled: true, alpha: 45, beta: 0 },
          },
          title: { text: CustomeDetailOfReport.title || "" },
          accessibility: { point: { valueSuffix: "%" } },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          credits: { enabled: false },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",

              colors:
                chartColoursFromJson?.map((color, index) => color) ||
                defaultColors,
              depth: 35,
              dataLabels: { enabled: true, format: "{point.name}" },
            },
          },
          series: [
            {
              boostThreshold: 1000,
              name: PreviewchartData?.series[0]?.name,
              colorByPoint: true,
              data: PreviewchartData?.series[0]?.data.map((name, index) => ({
                name,
                y: PreviewchartData?.series[1]?.data[index],
              })),
            },
          ],
        };
        break;
      case "3D Donut":
        options = {
          chart: { type: "pie", options3d: { enabled: true, alpha: 45 } },
          title: { text: CustomeDetailOfReport.title || "" },
          accessibility: { point: { valueSuffix: "%" } },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          credits: { enabled: false },
          plotOptions: {
            pie: {
              innerSize: 100,
              depth: 45,

              colors: chartColoursFromJson || defaultColors,
            },
          },
          series: [
            {
              boostThreshold: 1000,
              name: PreviewchartData?.series[0]?.name,
              colorByPoint: true,
              data: PreviewchartData?.series[0]?.data.map((name, index) => ({
                name,
                y: PreviewchartData?.series[1]?.data[index],

                color:
                  chartColoursFromJson[index % chartColoursFromJson.length] ||
                  defaultColors[index % defaultColors.length],
              })),
            },
          ],
        };
        break;
      case "donut":
        const total = PreviewchartData?.series[1]?.data.reduce(
          (sum, value) => sum + value,
          0
        );

        options = {
          chart: {
            type: "pie",
            events: {
              render: function () {
                // Add the total at the center of the donut
                if (!this.customLabel) {
                  this.customLabel = this.renderer
                    .text(
                      `Total<br><b>${total}</b>`,
                      this.plotLeft + this.plotWidth / 2,
                      this.plotTop + this.plotHeight / 2
                    )
                    .css({
                      color: "#000",
                      fontSize: "16px",
                      textAlign: "center",
                    })
                    .attr({
                      align: "center",
                      zIndex: 5,
                    })
                    .add();
                } else {
                  this.customLabel.attr({
                    text: `Total<br><b>${total}</b>`,
                  });
                }
              },
            },
          },
          title: { text: CustomeDetailOfReport.title || "" },
          accessibility: { point: { valueSuffix: "%" } },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          credits: { enabled: false },
          plotOptions: {
            pie: {
              innerSize: "70%",
              colors: chartColoursFromJson || defaultColors,
              dataLabels: {
                enabled: false,
                format: "{point.name}: {point.y}",
              },
            },
            showInLegend: true,
          },
          series: [
            {
              boostThreshold: 1000,
              name: PreviewchartData?.series[0]?.name,
              colorByPoint: true,
              data: PreviewchartData?.series[0]?.data.map((name, index) => ({
                name,
                y: PreviewchartData?.series[1]?.data[index],
                color:
                  chartColoursFromJson[index % chartColoursFromJson.length] ||
                  defaultColors[index % defaultColors.length],
              })),
            },
          ],
        };
        break;
      case "geomap":
        if (!topology) return {};
        options = {
          chart: { map: topology },
          title: { text: CustomeDetailOfReport.title || "" },
          mapNavigation: {
            enabled: true,
            buttonOptions: { verticalAlign: "bottom" },
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  click: function () {
                    alert(this.name);
                  },
                },
              },
              dataLabels: { enabled: true, style: { textOutline: false } },
            },
          },
          colorAxis: {
            min: 0,
            stops: Object.values(HighChartsColors)
              .slice(0, 5)
              .map((color, index) => [index / 4, color]),
          },
          series: [
            {
              boostThreshold: 1000,
              name: PreviewchartData?.series[0]?.name,
              data: PreviewchartData?.series[0]?.data.map((name, index) => ({
                name,
                value: PreviewchartData?.series[1]?.data[index],

                color:
                  chartColoursFromJson[index % chartColoursFromJson.length] ||
                  defaultColors[index % defaultColors.length],
              })),
              states: { hover: { color: "#2BD925" } },
              dataLabels: { enabled: true, format: "{point.name}" },
            },
          ],
        };
        break;
      case "pie":
        options = {
          chart: { type: "pie" },
          title: { text: CustomeDetailOfReport.title || "" },
          yAxis: [
            { title: { text: "Values" } },
            { opposite: true, title: { text: "Time" } },
          ],
          tooltip: {
            valueSuffix: "%",
          },
          plotOptions: {
            series: {
              allowPointSelect: true,
              cursor: "pointer",
              showInLegend: true,
              colors: chartColoursFromJson || defaultColors,
              dataLabels: [
                {
                  enabled: false,
                  distance: 20,
                },
                {
                  enabled: true,
                  distance: -40,
                  format: "{point.percentage:.1f}%",
                  style: {
                    fontSize: "1.2em",
                    textOutline: "none",
                    opacity: 0.7,
                  },
                  filter: {
                    operator: ">",
                    property: "percentage",
                    value: 10,
                  },
                },
              ],
            },
          },
          credits: { enabled: false },
          series: [
            {
              boostThreshold: 1000,
              name: PreviewchartData?.series[0]?.name,
              colorByPoint: true,
              data: PreviewchartData?.series[0]?.data.map((name, index) => ({
                name,
                y: PreviewchartData?.series[1]?.data[index],
              })),
            },
          ],
        };

        break;
      case "stackarea":
        options = {
          chart: { type: "area" },
          title: { text: CustomeDetailOfReport?.title || "" },
          yAxis: [
            { title: { text: "Values" } },
            { opposite: true, title: { text: "Time" } },
          ],
          xAxis: { categories: PreviewchartData.xAxis[0].categories },
          plotOptions: {
            area: {
              stacking: "normal",
              lineColor: "#666666",
              lineWidth: 1,
              marker: {
                lineWidth: 1,
                lineColor: "#666666",
              },
            },
          },
          credits: { enabled: false },
          series: PreviewchartData?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data?.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,

              color:
                chartColoursFromJson[index % chartColoursFromJson.length] ||
                defaultColors[index % defaultColors.length],
            })),
        };
        break;
      case "stackbar":
        if (columnCount > 2) {
          options = {
            chart: { type: "bar" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: "Values" } },
              { opposite: true, title: { text: "Time" } },
            ],

            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            xAxis: { categories: PreviewchartData?.xAxis[0].categories },
            credits: { enabled: false },
            series: PreviewchartData.series.map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,

              color: chartColoursFromJson[index % chartColoursFromJson.length],
            })),
          };
        } else if (columnCount <= 2) {
          options = {
            chart: { type: "bar" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: "Values" } },
              { opposite: true, title: { text: "Time" } },
            ],

            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            xAxis: { categories: PreviewchartData?.xAxis[0].categories },
            credits: { enabled: false },
            series: PreviewchartData?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data?.every(
                    (item) => typeof item === "string"
                  );
                }
                return true;
              })
              .map((series, index) => {
                const categoryColors =
                  PreviewchartData?.xAxis[0]?.categories.map(
                    (category, catIndex) =>
                      chartColoursFromJson[
                      catIndex % chartColoursFromJson.length
                      ]
                  );

                return {
                  boostThreshold: 1000,
                  name: series.name,
                  data: series.data.map((dataPoint, pointIndex) => ({
                    y: dataPoint,
                    color: categoryColors[pointIndex % categoryColors.length],
                  })),
                };
              }),
          };
        }
        break;
      case "stackcolumn":
        if (columnCount > 2) {
          options = {
            chart: { type: "column" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: "Values" } },
              { opposite: true, title: { text: "Time" } },
            ],
            xAxis: { categories: PreviewchartData?.xAxis[0].categories },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            credits: { enabled: false },
            series: PreviewchartData?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data?.every(
                    (item) => typeof item === "string"
                  );
                }
                return true;
              })
              .map((series, index) => ({
                boostThreshold: 1000,
                name: series.name,
                data: series.data,
                color:
                  chartColoursFromJson[index % chartColoursFromJson.length] ||
                  defaultColors[index % defaultColors.length],
              })),
          };
        } else if (columnCount <= 2) {
          options = {
            chart: { type: "column" },
            title: { text: CustomeDetailOfReport.title || "" },
            yAxis: [
              { title: { text: "Values" } },
              { opposite: true, title: { text: "Time" } },
            ],
            xAxis: { categories: PreviewchartData?.xAxis[0].categories },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            credits: { enabled: false },
            series: PreviewchartData?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data?.every(
                    (item) => typeof item === "string"
                  );
                }
                return true;
              })
              .map((series, index) => {
                const categoryColors =
                  PreviewchartData?.xAxis[0]?.categories.map(
                    (category, catIndex) =>
                      chartColoursFromJson[
                      catIndex % chartColoursFromJson.length
                      ]
                  );

                return {
                  boostThreshold: 1000,
                  name: series.name,
                  data: series.data.map((dataPoint, pointIndex) => ({
                    y: dataPoint,
                    color:
                      categoryColors[pointIndex % categoryColors.length] ||
                      defaultColors[pointIndex % defaultColors.length],
                  })),
                };
              }),
          };
        }
        break;
      case "speedometer":
        options = {
          chart: {
            type: "gauge",
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: "30%",
          },

          title: { text: CustomeDetailOfReport.title || "" },

          pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ["50%", "75%"],
            size: "110%",
          },
          yAxis: {
            min: 0,
            max: 200,
            tickPixelInterval: 72,
            tickPosition: "inside",
            tickColor:
              Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
            tickLength: 20,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
              distance: 20,
              style: {
                fontSize: "14px",
              },
            },
            lineWidth: 0,
            plotBands: [
              {
                from: 0,
                to: 30,

                color: chartColoursFromJson[0],
                thickness: 20,
                borderRadius: "50%",
              },
              {
                from: 31,
                to: 50,

                color: chartColoursFromJson[1],
                thickness: 20,
                borderRadius: "50%",
              },
              {
                from: 51,
                to: 200,

                color: chartColoursFromJson[2],
                thickness: 20,
              },
            ],
          },

          series: [
            {
              boostThreshold: 1000,
              name: PreviewchartData.series[0].name,
              data: PreviewchartData?.series[0].data,
              dataLabels: {
                borderWidth: 0,
                color:
                  (Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color) ||
                  "#333333",
                style: {
                  fontSize: "16px",
                },
              },
              dial: {
                radius: "80%",

                backgroundColor: chartColoursFromJson[3],
                baseWidth: 12,
                baseLength: "0%",
                rearLength: "0%",
              },
              pivot: {
                backgroundColor: chartColoursFromJson[4],
                radius: 6,
              },
            },
          ],
        };
        break;
      case "gauge":
        options = {
          chart: {
            type: "solidgauge",
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
          },

          title: { text: CustomeDetailOfReport.title || "" },
          pane: {
            center: ["50%", "85%"],
            size: "100%",
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
              innerRadius: "60%",
              outerRadius: "100%",
              shape: "arc",
            },
          },

          credits: { enabled: false },
          yAxis: {
            min: 0,
            max: 10000,
            tickPixelInterval: 72,
            tickPosition: "inside",

            tickLength: 20,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
              distance: 20,
              style: {
                fontSize: "14px",
              },
            },

            plotBands: [
              {
                from: 0,
                to: 120,

                color: chartColoursFromJson[0],
                thickness: 40,
              },
              {
                from: 120,
                to: 160,

                color: chartColoursFromJson[1],
                thickness: 40,
              },
              {
                from: 160,
                to: 200,

                color: chartColoursFromJson[2],
                thickness: 40,
              },
            ],
          },

          exporting: {
            enabled: false,
          },

          series: [
            {
              boostThreshold: 1000,
              name: PreviewchartData.series[0].name,
              data: PreviewchartData?.series[0].data,
              color:
                HighChartsColors.gray ||
                Highcharts.defaultOptions.chart.backgroundColor,
              dataLabels: {
                borderWidth: 0,
                color:
                  (Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color) ||
                  "#333333",
                style: {
                  fontSize: "16px",
                },
              },
              dial: {
                radius: "80%",

                backgroundColor: chartColoursFromJson[3],
                baseWidth: 12,
                baseLength: "0%",
                rearLength: "0%",
              },
              pivot: {
                backgroundColor: chartColoursFromJson[4],
                radius: 6,
              },
            },
          ],
        };

        break;
      case "radialBar":
        options = {
          chart: {
            type: "column",
            polar: true,
            inverted: true,
          },
          title: {
            text: CustomeDetailOfReport.title || "",
          },
          pane: {
            size: "85%",
            innerSize: "40%",
            startAngle: 0,
            endAngle: 270,
          },
          yAxis: [
            {
              lineWidth: 0,
              tickInterval: 25,
              reversedStacks: false,
              endOnTick: true,
              showLastLabel: true,
              gridLineWidth: 0,
              title: { text: "Values" },
            },
            {
              opposite: true,
              title: { text: "Time" },
            },
          ],
          xAxis: {
            tickInterval: 1,
            labels: {
              align: "right",
              useHTML: true,
              allowOverlap: true,
              step: 1,
              y: 3,
              style: { fontSize: "13px" },
            },
            lineWidth: 0,
            gridLineWidth: 0,
            categories: PreviewchartData?.xAxis[0].categories.map(
              (category, index) =>
                `${category} <span class="f16"><span id="flag" class="flag ${index}"></span></span>`
            ),
          },
          plotOptions: {
            column: {
              stacking: "normal",
              borderWidth: 0,
              pointPadding: 0,
              groupPadding: 0.15,
              borderRadius: "50%",
            },
            series: {
              stacking: "normal",
            },
          },
          credits: { enabled: false },
          series: PreviewchartData?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data?.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,

              color:
                chartColoursFromJson[index % chartColoursFromJson.length] ||
                defaultColors[index % defaultColors.length],
            })),
        };
        break;
      case "3Darea":
        options = {
          chart: {
            type: "area",
            options3d: { enabled: true, alpha: 15, beta: 30, depth: 200 },
          },
          title: { text: CustomeDetailOfReport.title || "" },
          accessibility: {
            keyboardNavigation: { seriesNavigation: { mode: "serialize" } },
          },
          lang: {
            accessibility: {
              axis: {
                xAxisDescriptionPlural:
                  "The chart has 3 unlabelled X axes, " +
                  "one for each series.",
              },
            },
          },
          yAxis: {
            title: { x: -40 },
            labels: { format: "{value:,.0f}" },
            gridLineDashStyle: "Dash",
          },
          xAxis: { categories: PreviewchartData?.xAxis[0].categories },
          plotOptions: {
            area: {
              depth: 100,
              marker: { enabled: false },
              states: { inactive: { enabled: false } },
            },
          },
          credits: { enabled: false },
          series: PreviewchartData?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data?.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,

              color:
                chartColoursFromJson[index % chartColoursFromJson.length] ||
                defaultColors[index % defaultColors.length],
            })),
        };
        break;
      default:
        break;
    }
    return options;
  }, [PreviewchartData, topology, chartColoursFromJson]);

  const handleColorChange = (legend, newColor) => {
    setCustomizationOptionsPreview((prevState) => {
      const updatedColors = { ...prevState.chart_colours };

      updatedColors[legend] = newColor;
      return {
        ...prevState,
        chart_colours: updatedColors,
      };
    });
  };

  return (
    <div className="superHighChartsDisplayView">
      <div className="previewChartpage highChartsDisplayView" id={containerId}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ id: containerId }}
          />
        )}
      </div>
      {CustomeDetailOfReport?.chart_type &&
        (CustomeDetailOfReport?.chart_type === "gauge" ||
          CustomeDetailOfReport?.chart_type === "speedometer") ? null : (
        <div
          className="draggable-toggle-button"
          onClick={() => setDragMe(!dragMe)}
        >
          <img
            src={drawOnChart}
            alt="drawOnChart"
            className="customize-chart-icon"
          />
        </div>
      )}
      <Offcanvas
        className="custom-color-super-container"
        show={dragMe}
        onHide={() => setDragMe(!dragMe)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Chart Color Configuration</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ minWidth: "300px" }}>
          <div className="highChartsColorConfigurationView">
            {totalLegends
              .filter((legend) => legends.includes(legend))
              .map((legend, index) => (
                <div key={index} className="custom-color-input">
                  <div className="custom-color-show">
                    <input
                      type="color"
                      value={
                        customizationOptionsPreview?.chart_colours[legend] ||
                        "#000000"
                      }
                      onChange={(e) =>
                        handleColorChange(legend, e.target.value)
                      }
                    />
                  </div>
                  <div className="custom-color-label">
                    {/*new changes*/}
                    <label>{legend}</label>
                    {/*new changes*/}
                  </div>
                </div>
              ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default PreviewHighchart;
