/*modified by Yuvraj Jaiswal */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Slider,
 CircularProgress,
  
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import GetAppIcon from "@mui/icons-material/GetApp";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MdPictureAsPdf } from "react-icons/md";
import { FaFileExcel, FaFileCsv } from "react-icons/fa";
import "./../globalCSS/previewhighcharts/previewreporttable.css";
import { customPreviewChartData } from "../../actions/auth";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import "./../globalCSS/reportmanagement/listofreports.css";
import "./../HighCharts/DrilldownStyleCorrector.css";
import { decryptData } from '../utils/EncriptionStore';

function PreviewReportTable() {
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const user = (() => {
    const encryptedData = localStorage.getItem("profile");
    return encryptedData ? decryptData(encryptedData) : null;
  })();
  const [loading, setLoading] = useState(true);
  const CustomeDetailOfReport = JSON.parse(
    localStorage.getItem("customeDetailOfReport")
  );

  const apiData = useSelector((state) => state?.auth);

    useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start the loader
        await dispatch(
          customPreviewChartData({
            report_name: CustomeDetailOfReport.title,
            report_type: CustomeDetailOfReport.type,
            chart_type: CustomeDetailOfReport.chart_type,
            query: CustomeDetailOfReport.query,
            email: user.user_email_id,
            database_type: "mysql",
            connection_type: CustomeDetailOfReport.connection_type,
            schema: CustomeDetailOfReport.schema,
          })
        );
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setLoading(false); // Stop the loader
      }
    };

    fetchData();
  }, []);

    const previewReportTableRowDiv = document.querySelector(".MuiTableRow-head")
    if (previewReportTableRowDiv) {
    previewReportTableRowDiv.classList.add("Prevresponsive-header");
    previewReportTableRowDiv.addEventListener("mouseenter", () => {
      previewReportTableRowDiv.style.backgroundColor = "#4d5256";
      previewReportTableRowDiv.style.color = "#fff";
    });
  }
  
    const nativeInputDiv = document.querySelector(".MuiSelect-nativeInput");
 
  if (nativeInputDiv) {
    nativeInputDiv.style.setProperty("opacity", "1", "important");
  }
 
  const downloadButtonDiv = document.querySelector(
    ".MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium"
  );
 
  if (downloadButtonDiv) {
    const spanElement = downloadButtonDiv.querySelector("span");
    if (spanElement) {
      spanElement.style.backgroundColor = ""; // Remove the background color
    }
  }
 
 
  const PreviewchartData = apiData?.custom_preview_table;

  const isDateTime = (value) => {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}[T\s]\d{2}:\d{2}(:\d{2})?$/;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateTimeRegex.test(value) || dateRegex.test(value);
  };

  useEffect(() => {
    if (PreviewchartData?.data.length > 0) {
      setData(PreviewchartData.data);
    }
  }, [PreviewchartData]);

  const columns = useMemo(() => {
    return (
      PreviewchartData?.column_names
        ?.filter((column) =>
          PreviewchartData?.data.some(
            (val) => val[column] !== null && val[column] !== undefined
          )
        )
        .map((column) => {
          const isDateColumn = PreviewchartData?.data.some((val) =>
            isDateTime(val[column])
          );
          const isIntegerColumn = PreviewchartData?.data.every((val) =>
            Number.isInteger(val[column])
          );

          if (isDateColumn) {
            return {
              accessorFn: (originalRow) => {
                const value = originalRow[column];
                return value ? new Date(value) : null;
              },
              id: column,
              header: column,
              Cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value) return "";
                return `${value.toLocaleDateString()} ${value.toLocaleTimeString()}`;
              },
              filterFn: (row, id, filterValue) => {
                const rowValue = row.getValue(id);
                const [start, end] = Array.isArray(filterValue)
                  ? filterValue
                  : [];
                const startDate = start ? new Date(start) : null;
                const endDate = end ? new Date(end) : null;
                const normalizedRowValue = new Date(
                  rowValue.setHours(0, 0, 0, 0)
                );
                const normalizedEndDate = endDate
                  ? new Date(endDate.setHours(0, 0, 0, 0))
                  : null;

                return (
                  (!startDate || normalizedRowValue >= startDate) &&
                  (!endDate || normalizedRowValue <= normalizedEndDate)
                );
              },
            };
          } else if (isIntegerColumn) {
            return {
              accessorFn: (row) => Number(row[column]),
              id: column,
              header: column,
              Cell: ({ cell }) => cell.getValue(),
              Filter: ({
                column: {
                  getFilterValue,
                  setFilterValue,
                  getFacetedMinMaxValues,
                },
              }) => {
                const [min, max] = getFacetedMinMaxValues() || [0, 100];
                const [minValue, maxValue] = getFilterValue() || [min, max];
                return (
                  <Box>
                    <Slider
                      value={[minValue, maxValue]}
                      onChange={(_, newValue) => setFilterValue(newValue)}
                      valueLabelDisplay="auto"
                      min={min}
                      max={max}
                    />
                  </Box>
                );
              },
              filterFn: (row, id, filterValue) => {
                const rowValue = row.getValue(id);
                const [min, max] = filterValue || [];
                return rowValue >= min && rowValue <= max;
              },
            };
          } else if (column != "" || column != null) {
            return {
              header: column,
              id: column,
              accessorFn: (row) => String(row[column]),
              enableClickToCopy: true,
              filterFn: (row, id, filterValue) => {
                const cellValue = row.getValue(id);
                if (Array.isArray(filterValue) && filterValue.length === 0) {
                  return true;
                }
                if (Array.isArray(filterValue)) {
                  return filterValue.some(
                    (value) =>
                      String(cellValue).toLowerCase() ===
                      String(value).toLowerCase()
                  );
                }
                return (
                  String(cellValue).toLowerCase() ===
                  String(filterValue).toLowerCase()
                );
              },
              muiTableBodyCellProps: {
                sx: {
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  flexGrow: 1,
                },
              },
              muiTableHeadCellProps: {
                sx: {
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  flexGrow: 1,
                },
              },
            };
          }
        }) || []
    );
  }, [data, PreviewchartData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.setFontSize(18);
    const pageWidth = doc.internal.pageSize.getWidth();
    const text =
      (CustomeDetailOfReport?.title && CustomeDetailOfReport?.title) || "";
    const textWidth = doc.getTextWidth(text);

    const textX = (pageWidth - textWidth) / 2;
    doc.text(text, textX, 10);

    const visibleColumns = columns.filter((column) =>
      table.getColumn(column.id).getIsVisible()
    );

    const tableData = rows.map((row) =>
      visibleColumns.map((column) => _.get(row.original, column.id))
    );
    const tableHeaders = visibleColumns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [44, 62, 80],
      },
      alternateRowStyles: {
        fillColor: [238, 238, 238],
      },
    });

    doc.save(`${CustomeDetailOfReport?.title}.pdf`);
  };

  const handleExportExcel = async (rows) => {
    const visibleColumns = columns.filter((column) =>
      table.getColumn(column.id).getIsVisible()
    );
    const tableData = rows.map((row) =>
      visibleColumns.map((column) => _.get(row.original, column.id))
    );
    const tableHeaders = visibleColumns.map((c) => c.header);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("ReportData");
    worksheet.mergeCells(1, 1, 1, visibleColumns.length);
    const titleCell = worksheet.getCell("A1");
    titleCell.value = CustomeDetailOfReport?.title || "Report Title";
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { horizontal: "center" };

    // Add headers
    const headerRow = worksheet.addRow(tableHeaders);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
    });

    // Add table data
    tableData.forEach((dataRow) => {
      worksheet.addRow(dataRow);
    });

    // Adjust column widths based on header length
    visibleColumns.forEach((_, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = Math.max(10, tableHeaders[index].length + 5);
    });

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Trigger file download
    const fileName = `${CustomeDetailOfReport?.title || "Report"}.xlsx`;
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  };

  const handleExportCSV = (rows) => {
    const visibleColumns = columns.filter((column) =>
      table.getColumn(column.id).getIsVisible()
    );

    const csvData = rows.map((row) =>
      visibleColumns.map((column) => _.get(row.original, column.id))
    );
    const csvHeaders = visibleColumns.map((c) => c.header);
    const sheetData = [
      [`${CustomeDetailOfReport?.title}`],
      [],
      csvHeaders,
      ...csvData,
    ];

    return {
      data: sheetData,
      filename: `${CustomeDetailOfReport?.title}.csv`,
    };
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: false,
    enableStickyFooter: true,
    paginationDisplayMode: "pages",
    enableHiding: false,
    enableColumnVisibility: false,
    enableFilters: false,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnResizing: false,
    //rowCount: data.meta.totalDBRowCount,
    muiTableProps: {
      sx: (theme) => ({
        "& td[data-pinned='true'],th[data-pinned='true']": {
          color: theme.palette.common.white,
        },
        padding: 0,
        margin: 0,
        width: "100%",
        overflowX: "auto",
      }),
    },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );
          setData([...data]);
        }
      },
    }),
    muiPaginationProps: {
      variant: "outlined",
      shape: "rounded",
      sx: (theme) => ({
        "& .Mui-selected": {
          background: `${theme.palette.primary.main} !important`,
          color: theme.palette.common.white,
        },
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Download">
            <IconButton onClick={handleClick}>
              <span style={{ backgroundColor: "#f9f9f9", height: "22px" }}>
                <GetAppIcon color="primary" />
              </span>
            </IconButton>
          </Tooltip>
          <Menu
            id="export-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
            >
              <MdPictureAsPdf color="primary" /> Export PDF
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleExportExcel(table.getPrePaginationRowModel().rows)
              }
            >
              <FaFileExcel color="primary" /> Export Excel
            </MenuItem>
            <MenuItem>
              <CSVLink
                {...handleExportCSV(table.getPrePaginationRowModel().rows)}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FaFileCsv color="primary" /> Export CSV
              </CSVLink>
            </MenuItem>
          </Menu>
        </Box>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", fontWeight: "bold", flex: 1 }}
        >
          {table?.getState()?.caption || CustomeDetailOfReport?.title}
        </Typography>
      </Box>
    ),
    initialState: {
      pagination: { pageSize: 8, pageIndex: 0 },
      density: "compact",
    },
  });

  

  return (
    <div>
      <div className="PreviewPage_table_main_container">
        <div>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box
                sx={{
                  height: 489,
                  width: "100%",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  overflowY: "scroll",
                }}
              >
                <MaterialReactTable
                  table={table}
                  sx={{
                    width: "100%",
                    border: "2px solid #ddd",
                    borderRadius: "10px",
                  }}
                  className="horizontal-stripe-table"
                />
              </Box>
            </LocalizationProvider>
          </div>)} 
         </div>
      </div>
    </div>
  );
}

export default PreviewReportTable;
